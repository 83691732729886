<template>
  <div class="message">
    <div class="title">
      <span>{{ titleIcon }}</span>
      <p>{{ title }}</p>
    </div>
    <div>
      <span ref="textSpan" class="typing-text">{{ typingText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleIcon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      typingText: '',
      typingSpeed: 100, // 타이핑 속도 (1글자당 ms)
    };
  },
  methods: {
    startTypingEffect() {
      const text = this.text;
      let i = 0;
      this.typingText = '';

      const typingInterval = setInterval(() => {
        this.typingText += text[i];
        i++;

        if (i === text.length) {
          clearInterval(typingInterval);
          this.$refs.textSpan.classList.add('end');
        }
      }, this.typingSpeed);
    },
  },
  watch: {
    text() {
      this.startTypingEffect();
    },
  },
  mounted() {
    this.startTypingEffect();
  },
};
</script>

<style scoped lang="scss">
.message {
  margin: 0 auto;
  color: #333;
  width: calc(100% - 32px);
  /* body-medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  padding: 10px 16px 12px 16px;
  border-radius: 8px;
  background: #fff;

  .title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    span {
      color: #333;
      /* imoji-large */
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
      margin-right: 8px;
    }

    p {
      color: #333;
      /* body-bold */
      font-family: Spoqa Han Sans Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
}

.typing-text {
  animation: typing 1s steps(40) infinite, blink-caret 0.2s step-end infinite;
  white-space: pre-line;
  border-right: 2px solid black;
}

.typing-text.end {
  border-right: none;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-right-color: transparent;
  }
  50% {
    border-right-color: black;
  }
}
</style>
