<template>
  <div class="container">
    <img src="../../assets/img/ai-loading.gif" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100vh;
  background-color: #ebebeb;
  z-index: 1000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
  }
}
</style>
