<template>
  <div class="message">
    <span ref="textSpan" class="typing-text">{{ typingText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      typingText: '',
      typingSpeed: 100, // 타이핑 속도 (1글자당 ms)
    };
  },
  methods: {
    startTypingEffect() {
      const text = this.text;
      let i = 0;
      this.typingText = '';

      const typingInterval = setInterval(() => {
        this.typingText += text[i];
        i++;

        if (i === text.length) {
          this.$refs.textSpan.classList.add('end');
          clearInterval(typingInterval);
        }
      }, this.typingSpeed);
    },
  },
  watch: {
    text() {
      this.startTypingEffect();
    },
  },
  mounted() {
    this.startTypingEffect();
  },
};
</script>

<style scoped lang="scss">
.message {
  margin: 0 auto;
  color: #fff;
  width: calc(100% - 32px);
  /* body-medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  padding: 8px 16px;
  border-radius: 8px;
  background: #666;
  margin-top: 16px;
}

.typing-text {
  animation: typing 1s steps(40) infinite, blink-caret 0.2s step-end infinite;
  white-space: pre-line;
  word-wrap: break-word;
  border-right: 2px solid white;
}

.typing-text.end {
  border-right: none;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-right-color: transparent;
  }
  50% {
    border-right-color: white;
  }
}
</style>
