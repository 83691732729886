<template>
  <div class="container">
    <div class="header">
      <p>AI 백선생</p>
      <v-icon @click="copyLink">mdi-export-variant</v-icon>
    </div>
    <div class="contents">
      <OnboardingMessage
        :text="`AI 백선생이에유~
네이버 플레이스 링크를 보내주세유~~`"
        v-if="['onboarding', 'linkInput'].includes(step)"
      />
      <LinkMessage v-if="step === 'linkInput'" :text="link" />
      <div style="position: sticky; top: 0; background-color: #ebebeb">
        <AnalysisDoneMessage
          text="분석이 끝났어유~ 어떤 얘기를 해드릴까유?"
          v-if="['resultList', 'checkResult'].includes(step)"
        />
      </div>
      <div v-if="step === 'resultList'" class="feedback-items">
        <FeedbackItem
          v-for="option in selectableOptions"
          :key="option.label"
          :icon="option.icon"
          :label="option.label"
          :isFull="true"
          @click="onFeedbackPressed(option.label, option.icon)"
        />
      </div>
      <div v-if="step === 'checkResult'">
        <div class="feedback-messages" ref="feedbackMessagesContainer">
          <FeedbackMessage
            v-for="option in selectedOption"
            :key="option.title"
            :text="option.content"
            :title="option.title"
            :titleIcon="option.titleIcon"
          />
        </div>
        <div class="feedback-options">
          <FeedbackItem
            v-for="option in selectableOptions"
            :key="option.label"
            :icon="option.icon"
            :label="option.label"
            @click="onFeedbackPressed(option.label, option.icon)"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <input
        v-if="step === 'onboarding'"
        v-model="link"
        type="text"
        @keyup.enter="submitLink"
      />
      <Button
        v-if="['resultList', 'checkResult'].includes(step)"
        label="매주 분석 받아보기"
        @click="moveAnalysisRequest"
      />
    </div>
    <div
      v-if="isURLError"
      style="
        position: fixed;
        border-radius: 8px;
        bottom: 30%;
        color: #fff;
        background: rgba(32, 32, 32, 0.4);
        padding: 8px 12px;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      "
    >
      링크를 제대로 넣은게 맞아유? 다시 한번 확인해봐유~!
    </div>
    <div
      v-if="isURLCopyNotice"
      style="
        position: fixed;
        border-radius: 8px;
        bottom: 30%;
        color: #fff;
        background: rgba(32, 32, 32, 0.4);
        padding: 8px 12px;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      "
    >
      링크를 복사가 완료되었습니다.
    </div>
    <Loading v-if="isGeneratedReport" />
  </div>
</template>

<script>
import OnboardingMessage from '../../components/ai/OnboardingMessage.vue';
import LinkMessage from '../../components/ai/LinkMessage.vue';
import AnalysisDoneMessage from '../../components/ai/AnalysisDoneMessage.vue';
import FeedbackItem from '../../components/ai/FeedbackItem.vue';
import Button from '../../components/ai/Button.vue';
import FeedbackMessage from '../../components/ai/FeedbackMessage.vue';
import Loading from '../../components/ai/Loading.vue';

/*
    step
    - onboarding : 시작
    - linkInput : 링크 입력
    - resultList : 결과 목록
    - checkResult : 결과 확인
*/

export default {
  name: 'ai',
  components: {
    OnboardingMessage,
    LinkMessage,
    AnalysisDoneMessage,
    FeedbackItem,
    Button,
    FeedbackMessage,
    Loading,
  },
  data() {
    return {
      step: 'onboarding',
      link: '',
      selectableOptions: [
        {
          icon: '😆',
          label: '칭찬 받기',
        },
        {
          icon: '😭',
          label: '쓴소리 듣기',
        },
        {
          icon: '✏️',
          label: '솔루션 듣기',
        },
        {
          icon: '🗒️',
          label: '전부 보기',
        },
      ],
      selectedOption: [],
      result: {},
      resultMap: {},
      isGeneratedReport: false,
      isURLError: false,
      isURLCopyNotice: false,
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText('https://supermembers.co.kr/aibaek/');
      this.isURLCopyNotice = true;

      setTimeout(() => {
        this.isURLCopyNotice = false;
      }, 2000);
    },
    async requestReport() {
      try {
        const result = await this.axios.get(
          `/ai/review/report?naverUrl=${this.link}`
        );

        this.result = result.data;
      } catch (error) {
        throw new Error();
      }
    },
    async requestFeedback(type) {
      try {
        const result = await this.axios.get(
          `/ai/review/report?id=${this.result.id}&type=${type}`
        );

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    async submitLink() {
      this.step = 'linkInput';
      this.isGeneratedReport = true;

      try {
        await this.requestReport();
        const [g, b, s] = await Promise.all([
          this.requestFeedback('g'),
          this.requestFeedback('b'),
          this.requestFeedback('s'),
        ]);

        this.resultMap = {
          '칭찬 받기': g.message,
          '쓴소리 듣기': b.message,
          '솔루션 듣기': s.message,
          '전부 보기': this.result.message,
        };
        this.step = 'resultList';
      } catch (error) {
        this.isURLError = true;

        setTimeout(() => {
          this.isURLError = false;
        }, 2000);
        this.step = 'onboarding';
      } finally {
        this.isGeneratedReport = false;
      }
    },
    async onFeedbackPressed(title, icon) {
      try {
        let content = '';

        switch (title) {
          case '칭찬 받기':
            {
              content = this.resultMap[title];
            }
            break;
          case '쓴소리 듣기':
            {
              content = this.resultMap[title];
            }

            break;
          case '솔루션 듣기':
            {
              content = this.resultMap[title];
            }
            break;
          case '전부 보기':
            {
              content = this.resultMap[title];
            }
            break;
        }
        const clone = [...this.selectedOption];

        clone.push({
          title,
          titleIcon: icon,
          content,
        });

        this.selectedOption = clone;

        const 제외한배열 = [...this.selectableOptions].filter(
          (option) => option.label !== title
        );

        this.selectableOptions = 제외한배열;
        this.step = 'checkResult';
      } catch (error) {
        console.log(error);
      }
    },
    moveAnalysisRequest() {
      this.$store.commit('setAiData', { data: this.resultMap });
      this.$router.push({ path: `/ai/analysis-request/${this.result.id}` });
    },
  },
  created() {
    if (this.$store.getters.aiData.data) {
      this.resultMap = this.$store.getters.aiData.data;
      this.step = 'resultList';
    }
  },
  beforeCreate() {
    document.querySelector('#loadingContainer').classList.add('remove-loading');
  },
  destroyed() {
    document
      .querySelector('#loadingContainer')
      .classList.remove('remove-loading');
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #ebebeb;
}

.header {
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  p {
    color: #333;
    text-align: center;
    /* subline-bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }

  button {
    color: #333;
    position: absolute;
    right: 16px;
  }
}

@media (min-width: 960px) {
  .footer {
    max-width: 960px;
  }
}

.footer {
  height: 85px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 16px;
  border-top: 1px solid rgba(204, 204, 204, 1);

  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ebebeb;

  input {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #fff;
    box-sizing: border-box;
    outline: none;
    height: 45px;
    width: 100%;
  }
}

.contents {
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 85px;
}

.feedback-items {
  width: calc(100% - 32px);
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  background: #666;
  margin-top: 20px;
}

.feedback-options {
  padding: 0px 16px;
  margin-top: 12px;
  display: flex;
  gap: 12px;
}

.feedback-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}
</style>

<style>
.remove-loading {
  display: none !important;
}
</style>
