<template>
  <div class="message" :style="{ height: expanded ? '38px' : '0' }">
    <span ref="textSpan" class="typing-text"
      ><v-icon
        style="color: rgba(45, 180, 0, 1); font-size: 20px; margin-right: 8px"
        >mdi-check-circle</v-icon
      >
      {{ text }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    this.expandMessage();
  },
  methods: {
    expandMessage() {
      setTimeout(() => {
        this.expanded = true;
      }, 1); // 100ms 뒤에 expanded를 true로 변경하여 펼쳐지는 애니메이션을 구현
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  margin: 0 auto;
  color: #fff;
  width: calc(100% - 32px);
  /* body-medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  padding: 0px 16px;
  height: 0px;
  border-radius: 8px;
  background: #1a1a1a;
  display: flex;
  align-items: center;

  transition: height 0.8s;
  overflow: hidden;

  span {
    display: flex;
    align-items: center;
  }
}
</style>
