<template>
  <button @click="handleClick">{{ label }}</button>
</template>

<script>
export default {
  props: ['label'],
  methods: {
    handleClick() {
      this.$emit('click'); // 클릭 이벤트를 부모 컴포넌트로 전달
    },
  },
};
</script>

<style scoped lang="scss">
button {
  height: 48px;
  padding: 12px 16px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  color: #fff;
  text-align: center;
  /* subline-bold */
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  border-radius: 8px;
  background: #1a1a1a;
}
</style>
